import React from 'react'
import { Button, Avatar } from '@mui/material'

const PrimaryButton = ({ onClick, disabled, startIcon, children, sx}) => {

 const handleOnClick = (e) => {
    e.stopPropagation();
    if (onClick) {
        onClick();
    }
  };

  return (
    <Button
        onClick={handleOnClick}
        disabled={disabled}
        sx={{
            background: "#0081FF",
            color: "white",
            fontSize: "1.125rem",
            lineHeight: "1.25rem",
            fontWeight: 500,
            whiteSpace: "nowrap",
            textAlign: "center",
            textTransform: "none",
            ...sx,
            "&:hover": {
                background: "white",
                color: "#0081FF",
                border: "1px solid #0081FF",
            },
        }}
        startIcon={startIcon?<Avatar src={startIcon} style={{ width: '1.25rem', height: '1.25rem' }}/>:null}
    >
        {children}
    </Button>
  )
}

export default PrimaryButton