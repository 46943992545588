import { Box, Button, Rating } from '@mui/material'
import React from 'react'
import BorderedBox from '../../components/BorderedBox'
import TargetAudienceGroup from "../../assets/target-audience-grp.svg"
import ExpensesImage from "../../assets/expenses-image.svg"
import ClientImage from "../../assets/client-image.svg"
import KheytiImage from "../../assets/kheyti.png"

const sectionHeadingStyle = {
   fontWeight:"700", 
   fontSize: { xs: "1.75rem", sm: "2.25rem", md: "2.625rem" }, 
   lineHeight: { xs: "2rem", sm: "2.5rem", md: "2.75rem" }, 
   textAlign:"center"
}

const FeaturesAndClientReviewSection = () => {

  const clientTestimonials = [
    {image:ClientImage, name:"Amit Sharma", location:"Mumbai", review:"Slatesy’s user-friendly interface and comprehensive features have revolutionized our HR management, making processes smoother and more efficient.", rating:"4"},
    {image:ClientImage, name:"Amit Sharma", location:"Pune", review:"Slatesy has transformed our HR processes with its intuitive platform. Managing employee records and payroll has never been easier.", rating:"4"},
    {image:ClientImage, name:"Amit Sharma", location:"Mumbai", review:"With Slatesy, our onboarding and leave management are streamlined and efficient.", rating:"5"}
  ];

  const handlePlayClick = () => {
    const video = document.getElementById('videoElement');
    if (video) {
      video.play();
    }
  };

  return (
    <Box>

        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
             <Box sx={{...sectionHeadingStyle}}>
                <span style={{color:"#0081FF"}}>Streamline global employment</span> with Slatesy<br/>
                Employer of Record Platform
             </Box>
             <BorderedBox sx={{marginTop:"1rem"}}>
                Employer of Record Platform
             </BorderedBox>
             <img src={TargetAudienceGroup} alt="target-audience-group" style={{ marginTop: "7.5rem", width: "100%", height: "auto", maxWidth: "84.43rem" }}/>
        </Box>

        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:{xs:"6rem", md:"12.375rem"}}}>
             <Box sx={{...sectionHeadingStyle}}>
                Empower Your Workforce With <span style={{color:"#0081FF"}}>Advanced Tools</span>
             </Box>
             <BorderedBox sx={{marginTop:"1rem"}}>
                Try Yourself!
             </BorderedBox>
             <Box sx={{display:"flex",justifyContent: "center", position: "relative", overflow: "hidden", paddingBottom:"6.25rem", width:{xs:"90%",md:"100%"}}}>
                  <Box 
                   sx={{borderRadius: "50%", width:"12.125rem", height:{xs:"auto", md:"12.125rem"}, backgroundColor:"#C2C3F8CC", zIndex: 1, 
                        position:"absolute", top:"10rem", left:"-0.5rem"
                       }}
                  >
                  </Box>
                  <Box sx={{width:"74.31rem", height:{xs:"auto", md:"45rem"},borderRadius:"1rem",marginTop:"7.5rem", position: 'relative',zIndex: 2,}}>
                     <video id="videoElement" style={{ width: "100%", height: "100%", borderRadius: "1rem", objectFit: 'cover'}} controls>
                        <source src="/path-to-your-video-file/video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                     </video>
                     <Box 
                        sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', borderRadius:"1rem",
                                 backgroundColor: '#000000B2', display: 'flex', justifyContent: 'center', alignItems: 'center' 
                        }}
                     >
                        <Button 
                           onClick={handlePlayClick} 
                           sx={{backgroundColor:"#FFFEFA", textTransform:"none", borderRadius:"1.5rem", width:{xs:"10rem", md:"21.32rem"}, height:{xs:"auto", md:"7.5rem"}, border:"1px solid #000000", 
                                 fontSize:"1.125rem", lineHeight:"1.25rem", color:"#000"}}
                        >
                              Click for interactive demo
                        </Button>
                     </Box>
                  </Box>
                  <Box 
                   sx={{borderRadius: "50%",width:"21.625rem",height:{xs:"auto", md:"21.625rem"}, backgroundColor:"#C2C3F8CC", zIndex: 1, 
                        position:"absolute", bottom:"0rem", right:"-6.5rem"
                     }}
                  > 
                  </Box>
             </Box>
        </Box>

        {/* <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", marginTop:"8.25rem"}}>
             <Box sx={{...sectionHeadingStyle}}>
               <span style={{color:"#0081FF"}}>Enjoy a seamless, responsive interface</span> <br/> experience across all devices.
             </Box>
             <BorderedBox sx={{marginTop:"1rem"}}>
                agfsvhgdjn
             </BorderedBox>
             <Box sx={{width:"80.18rem", height:"34.18rem", borderRadius:"1rem", backgroundColor:"#FFFFFF", marginTop:"7.5rem"}}>

             </Box>
        </Box> */}

        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center",  marginTop: { xs: "4rem", md: "8.25rem" }, paddingInline: { xs: "1.5rem", md: "3.125rem" }, mb: { xs: "2rem", md: "0" } }}>
             <Box sx={{...sectionHeadingStyle}}>
                 Save <span style={{color:"#0081FF"}}>money on travel!</span>
             </Box>
             <BorderedBox sx={{marginTop:"1rem"}}>
                We have saved millions for our clients
             </BorderedBox>
             <Box 
               sx={{
                  width:"100%", 
                  display:"flex", 
                  flexDirection: { xs: "column", lg: "row" }, 
                  alignItems:"stretch",  
                  justifyContent:"center",
                  gap: { xs: "2rem", lg: "1rem" }, 
                  height: { xs: "auto", lg: "33.375rem" }, 
                  marginTop: { xs: "4rem", lg: "7.5rem" }, 
                  paddingInline: { xs: "1rem", lg: "0" } 
               }}>
                <Box 
                  sx={{ 
                     width: { xs: "100%", lg: "33.875rem" }, 
                     borderRadius:"10px", 
                     backgroundColor:"#fff", 
                     display:"flex", 
                     flexDirection:"column", 
                     alignItems:"center", 
                     justifyContent:"center", 
                     paddingInline: { xs: "1rem", lg: "1.625rem" },
                  }}>
                    <img src={KheytiImage} alt="kisan-agro"/>
                    <Box sx={{
                              fontSize: { xs: "1rem", lg: "1.25rem" }, 
                              lineHeight:"1.4375rem", 
                              fontWeight:"700", 
                              color:"#636363"
                            }}
                    >Ajay Mishra</Box>
                    <Box sx={{
                             fontSize: { xs: "0.875rem", lg: "1rem" }, 
                             lineHeight:{ xs: "1.5rem", lg: "1.9375rem" },
                             fontFamily:"Lato", 
                             textAlign:"center"
                             }}
                    >
                     “The expense management feature in Slatesy has been a game-changer for us. It simplifies expense tracking and approvals, ensuring transparency and control over our budget. The streamlined process saves us time and reduces errors.”
                    </Box>
                </Box>
                <img 
                  src={ExpensesImage} 
                  alt="expenses-image" 
                  style={{width: { xs: "100%", lg: "48.75rem" },height: "auto", maxWidth: "100%", boxShadow: "0px 20px 40px 0px #0000001A"}} 
               /> 
             </Box>
        </Box>

        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center",  marginTop: { xs: "7rem", md: "13.81rem" }, paddingInline: { xs: "1.5rem", md: "3.125rem" }}}>
             <Box sx={{...sectionHeadingStyle}}>
                See What Clients are Saying <span style={{color:"#0081FF"}}>About Us</span>
             </Box>
             <BorderedBox sx={{marginTop:"1rem"}}>
                Testimonial
             </BorderedBox>
             <Box sx={{
               width:"100%", 
               marginTop:"5.95rem", 
               display:"flex",  
               flexDirection: { xs: "column", sm: "row" }, 
               gap: { xs: "2rem", sm: "3rem" }, 
               justifyContent: { xs: "center", sm: "space-between" }, 
               alignItems:"center",
               marginBottom: { xs: "5rem", md: "10.31rem" }
            }}>
                {
                    clientTestimonials?.map((item)=>(
                       <Box sx={{width:"25.5rem", height:"15.68rem", display:"flex", flexDirection:"column", alignItems:"center", borderRadius:"1.38rem", backgroundColor:"#FFFFFFD6", padding:"1.5rem", position: "relative"}}>
                          <img 
                             src={item?.image} 
                             style={{
                                height: "5rem",
                                width: "5rem",
                                position: "absolute",
                                top: "-2.5rem", 
                                borderRadius: "50%", 
                              }} 
                          />
                          <Box sx={{fontWeight:"600", fontSize:"1.375rem",lineHeight:"2.0625rem",color:"#1E1E1E"}}>{item?.name}</Box>
                          <Box sx={{fontFamily:"Lato", fontWeight:"600", fontSize:"0.875rem",lineHeight:"1.125rem",color:"#1E1E1E99"}}>{item?.location}</Box>
                          <Box  sx={{wordWrap: "break-word", overflow: "hidden", fontSize:"0.875rem",lineHeight:"1.68rem", marginBlock:"1rem"}}>
                            {item?.review}
                          </Box>
                          <Box>
                             <Rating value={item?.rating}/>
                          </Box>
                        </Box>
                    ))
                }
             </Box>
        </Box>

    </Box>
  )
}

export default FeaturesAndClientReviewSection