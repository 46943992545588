import React, { useState } from 'react'
import { Box, Button, IconButton,Drawer, List, ListItemText, ListItem, useMediaQuery, useTheme } from '@mui/material'
import SlatesyIcon from '../assets/slatesy-icon.svg'
import PrimaryButton from './PrimaryButton'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const navItemStyling ={
    padding:{xs:"0.5rem", sm:"0.625rem"},
    fontSize: "1.125rem",
    lineHeight: "1.25rem",
    fontWeight: 500
}

const Navbar = () => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = ['Products', 'About', 'Pricing', 'Resources'];

  return (
    <Box sx={{
              width:"100%", 
              display:"flex", 
              alignItems:"center", 
              justifyContent:"space-between", 
              padding: isMobile ? '1.125rem 0.5rem' : '1.125rem 2rem', 
              boxShadow: "0px 9px 20px 0px #0000001A",
              position: "fixed",      
              top: 0,                
              left: 0,                
              zIndex: 1000,           
              backgroundColor: "#fff", 
            }}>
         <img src={SlatesyIcon} alt="slatesy-icon"  style={{ width: isMobile ? '120px' : 'auto' }}/>
         
         {isMobile ? (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer}
                    >
                    <MenuIcon />
                    </IconButton>
                ) : (
                    <Box sx={{ display: 'flex', gap:{sm:'0.1rem', md:'4rem'}, flexDirection: 'row' }}>
                    {menuItems.map((item) => (
                        <Box key={item} sx={{...navItemStyling}}>{item}</Box>
                    ))}
                    </Box>
         )}
                
        {!isMobile && (
            <Box sx={{ display: 'flex', gap: {sm:'0.1rem', md:'2.25rem' }}}>
            <Button
                sx={{
                fontSize: '1.125rem',
                lineHeight: '1.25rem',
                fontWeight: 500,
                color: '#000',
                textTransform: 'none',
                padding: '0.625rem 1.375rem',
                }}
            >
                Login
            </Button>
            <PrimaryButton sx={{ borderRadius: '10px', padding: '0.625rem 1.375rem' }}>
                Demo
            </PrimaryButton>
            </Box>
        )}

         {/* Drawer for Mobile Screens */}
        <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
            <Box
            sx={{
                width: 250,
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
            }}
            >
            <IconButton
                edge="start"
                color="inherit"
                aria-label="close"
                onClick={toggleDrawer}
            >
                <CloseIcon />
            </IconButton>
            <List>
                {menuItems.map((item) => (
                <ListItem button key={item} onClick={toggleDrawer}>
                    <ListItemText primary={item} />
                </ListItem>
                ))}
                <ListItem button onClick={toggleDrawer}>
                <ListItemText primary="Login" />
                </ListItem>
                <ListItem button onClick={toggleDrawer}>
                <PrimaryButton sx={{ borderRadius: '10px', padding: '0.625rem 1.375rem' }}>
                    Demo
                </PrimaryButton>
                </ListItem>
            </List>
            </Box>
        </Drawer>
    </Box>
  )
}

export default Navbar