import { Box, Button } from '@mui/material'
import React, {useRef} from 'react'
import SlatesyDashboard from '../../assets/slatesy-dashboard.svg'
import BorderedBox from '../../components/BorderedBox'
import CarouselCard from '../../components/CarouselCard'
import CardImage from '../../assets/card-image.svg'
import Wave from '../../assets/wave-down.svg'

const WhatWeDoSection = () => {

   const cardContainerRef = useRef(null); 

   const features = [
      {image: CardImage, title: "Leave Management", description: "Efficiently manage employee leave requests with approvals, leave balance tracking to ensure smooth operations and adequate staffing levels."},
      {image: CardImage, title: "Onboarding", description: "Streamline the onboarding process with a structured workflow for new hires."},
      {image: CardImage, title: "Payroll and Analytics", description: "Automate payroll processing with accurate calculations, tax deductions, and compliance checks. Gain insights through analytics to track payroll expenses, compensation trends, and financial forecasts."},
      {image: CardImage, title: "Attendance and Timesheet", description: "Monitor and record employee attendance and work hours with ease. Manage timesheets, track attendance patterns to ensure accurate timekeeping and compliance."},
      {image: CardImage, title: "Expense Management", description: "Simplify expense tracking and approval workflows with a centralized system for submitting, reviewing, and reimbursing employee expenses, while maintaining budget control and compliance."},
      {image: CardImage, title: "Trips", description: "Manage business trips efficiently."}
  ];

  const btnPressPrev = () =>{
    let box = cardContainerRef.current;
    console.log(box);
    let width = box.clientWidth;
    box.scrollLeft = box.scrollLeft - width;
  }

  const btnPressNext = () =>{
    let box = cardContainerRef.current;
    let width = box?.clientWidth;
    box.scrollLeft = box.scrollLeft + width;
  } 

  return (
   <Box>
    <Box sx={{width:"100%", backgroundColor:"#0081FF1A", padding:{xs: "3rem 1.5rem", md: "9.5rem 6rem 1.875rem 2.5rem"}}}>
         <Box sx={{display:"flex", justifyContent:"space-between", flexDirection: { xs: "column", lg: "row" }, alignItems:{xs:"center", lg:"start"}}}>
               <img src={SlatesyDashboard} alt={"slatesy dashboard"} style={{ width: "100%", maxWidth: "55.5rem", height: "auto" }}/>
               <Box sx={{width: { xs: "100%", md: "19.93rem" }, display:"flex", flexDirection:"column", alignItems:"center"}}>
                  <Box sx={{fontSize:{xs: "1.75rem", md:"2.25rem"}, fontWeight:"700"}}>
                     Get Slatesy <span style={{color:"#0081FF"}}>fied</span>
                  </Box>
                  <BorderedBox sx={{width:"9.875rem", height:"1.875rem", padding:"4px 0px", marginTop:"1rem"}}>
                    No pun intended
                  </BorderedBox>
                  <Box sx={{marginTop: { xs: "2rem", md: "6.25rem" }, fontSize:{xs:"0.75rem", md:"1rem"}, lineHeight:"2rem", textAlign:"justify",ol: {paddingLeft: "1rem"}}}>
                        Why are we different from other HRMS portals?
                        <ol>
                            <li>User-Friendly Interface</li>
                            <li>Scalable Solutions</li>
                            <li>Customizable Features</li>
                            <li>Advanced Analytics</li>
                        </ol>
                  </Box>
               </Box>
         </Box>
         <Box 
          sx={{marginTop:{xs:"4rem", md:"8rem"},  position:"relative", display:"flex", alignItems:"center", }}
         >
               <Button 
                 sx={{fontWeight:"500", borderRadius:"50%", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"}} 
                 onClick={btnPressPrev}
               >
                  &lt;
               </Button>
               <Button 
                 sx={{fontWeight:"500", position:"absolute", right:0, borderRadius:"50%",boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"}} 
                 onClick={btnPressNext}
               >
                  &gt;
               </Button>
               <Box 
                 ref={cardContainerRef}
                 sx={{display: "flex" , gap:"3.84rem", overflowX: "hidden", overflowY:"hidden", scrollBehavior: "smooth"}}
               >
                {
                    features.map((item)=>(
                       <CarouselCard>
                          <Box sx={{display:"flex", gap: { xs: "0.75rem", md: "1.21rem" }}}>
                              <img src={item?.image} alt="card image"/>
                              <Box sx={{fontWeight:"600",fontSize: { xs: "1rem", md: "1.3125rem" }, lineHeight: { xs: "1rem", md: "2.15rem" }}}>
                                 {item?.title}
                              </Box>
                          </Box>
                          <Box sx={{
                           fontFamily:"Lato", 
                           fontSize: { xs: "0.875rem", md: "1rem" }, 
                           lineHeight: { xs: "1.25rem", md: "1.4rem" }, 
                           paddingBlock: { xs: "1rem", md: "1.4rem" }, 
                           marginTop: { xs: "0.75rem", md: "1.25rem" },
                           }}>
                                 {item?.description}
                          </Box>
                       </CarouselCard>
                    ))
                }
               </Box>
         </Box>
    </Box>
    <img src={Wave} alt='wave'/>
    </Box>
  )
}

export default WhatWeDoSection