import React from 'react'
import { Box } from '@mui/material'

const CarouselCard = ({children}) => {
  return (
    <Box 
      sx={{
        minWidth:"22.125rem",
        borderRadius:"1rem", 
        backgroundColor:"#fff", 
        padding:"1.7812rem 1.5rem",
        height:"17.0625rem"
      }}
    >
      {children}
    </Box>
  )
}

export default CarouselCard