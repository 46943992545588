import React, { useState } from 'react';
import { Box, TextField} from '@mui/material';
import PrimaryButton from './PrimaryButton';

const EmailInputWithButton = ({ onSubmit, sx }) => {
  const [email, setEmail] = useState('');

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleClick = () => {
    if (onSubmit) {
      onSubmit(email);
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #0081FF',
      borderRadius: '20px',
      padding: '1rem 0.75rem',
      width: '27.25rem',
      height:"5rem",
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      backgroundColor:"#FFF",
      ...sx
    }}>
      <TextField
        value={email}
        onChange={handleChange}
        placeholder="Enter your email"
        sx={{
          flex:1,
          "& .MuiOutlinedInput-notchedOutline":{border:"none"}
        }}
      />
      <PrimaryButton
        onClick={handleClick}
        sx={{width:"8.3125rem", height:"3.5rem", borderRadius:"10px", padding:"1rem", fontFamily:"Lato", fontSize:"1rem"}}
      >
        Get Started
      </PrimaryButton>
    </Box>
  );
}

export default EmailInputWithButton;
