import React from 'react';
import { Box } from '@mui/material';

const BorderedBox = ({ children,sx}) => {
  return (
    <Box
      sx={{
        border: "1px solid #7152F3",
        textAlign: "center",
        borderRadius: "1rem",
        padding:"4px 74px",
        fontFamily:"Lato",
        fontWeight:"700",
        fontSize:"1rem",
        ...sx
      }}
    >
      {children}
    </Box>
  );
}

export default BorderedBox;
