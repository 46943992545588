import React from 'react'
import { Box } from '@mui/material'
import MainSection from './MainSection'
import WhatWeDoSection from './WhatWeDoSection'
import FeaturesAndClientReviewSection from './FeaturesAndClientReviewSection'

const LandingPage = () => {
  return (
    <Box sx={{backgroundColor:"#FAFAFA"}}>
       <MainSection/>
       <Box sx={{marginTop:"1rem"}}>
           <WhatWeDoSection/>
       </Box>
       <FeaturesAndClientReviewSection/>
    </Box>
  )
}

export default LandingPage